import React, { Component } from "react";
import Navbar from "../components/Navbar";
import debounce from "lodash.debounce";
import Footer from "../components/Footer";

const tailor = async (messageID, data = {}) => {
	let apiURL;

	if (process.env.GATSBY_REACT_APP_ENV === "staging") {
		apiURL = "https://api.staging.tuzagdesign.com/tailor/message";
	} else {
		apiURL =
			process.env.NODE_ENV === "development"
				? "http://localhost:3000/tailor/message"
				: "https://api.v2.tuzagdesign.com/tailor/message";
	}

	data.apiKey = "nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D";
	data.returnElementSlugs = true;

	const unParsedData = await fetch(`${apiURL}/${messageID}`, {
		method: "POST",
		credentials: "include",
		mode: "cors",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(data)
	});

	return await unParsedData.json();
};

export default class PrivacyPolicy extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount = async () => {
		window.addEventListener("scroll", this.setScrollDataInState);

		this.setState({
			tailorData: await tailor("4295,4234")
		});
	};

	setScrollDataInState = debounce(() => {
		this.setState({
			scrollTop: document.body.scrollTop || document.documentElement.scrollTop
		});
	}, 50);

	tailorData(key, mid, raw = false) {
		try {
			if (this.state.tailorData) {
				if (raw) {
					return this.state.tailorData[mid][key];
				}
				return (
					<span
						dangerouslySetInnerHTML={{
							__html: this.state.tailorData[mid][key]
						}}
					/>
				);
			} else {
				return <span />;
			}
		} catch (e) {
			//do nothing
		}
	}

	render() {
		return (
			<div>
				<Navbar
					tailorData={this.state.tailorData}
					scrollTop={this.state.scrollTop}
					opaque={true}
					privacyPolicy={true}
				/>
				{this.state.tailorData ? (
					<div className="container" style={{ marginTop: 100 }}>
						<div className="text-center">
							<h3>{this.tailorData("title", 4295)}</h3>
						</div>
						<p>{this.tailorData("body", 4295)}</p>
					</div>
				) : null}
				<Footer tailorData={this.state.tailorData} />
			</div>
		);
	}
}
